export default [
  {
    code: "06-9011",
    format: "11/A",
    size: "120x175 (100x165)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 200,
  },
  {
    code: "06-9111",
    format: "11/A",
    size: "120x175 (100x165)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 200,
  },
  {
    code: "06-9016",
    format: "12/B",
    size: "135x225 (115x215)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 200,
  },
  {
    code: "06-9116",
    format: "12/B",
    size: "135x225 (115x215)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 200,
  },
  {
    code: "06-9021",
    format: "13/C",
    size: "170x225 (150x215)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9121",
    format: "13/C",
    size: "170x225 (150x215)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9026",
    format: "14/D",
    size: "200x275 (180x265)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9126",
    format: "14/D",
    size: "200x275 (180x265)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9031",
    format: "15/E",
    size: "235x275 (215x265)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9131",
    format: "15/E",
    size: "235x275 (215x265)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9036",
    format: "16/F",
    size: "235x350 (215x340)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9136",
    format: "16/F",
    size: "235x350 (215x340)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9041",
    format: "17/G",
    size: "250x350 (230x340)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9141",
    format: "17/G",
    size: "250x350 (230x340)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9046",
    format: "18/H",
    size: "285x370 (265x360)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9146",
    format: "18/H",
    size: "285x370 (265x360)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 100,
  },
  {
    code: "06-9051",
    format: "19/I",
    size: "315x455 (295x445)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
  {
    code: "06-9151",
    format: "19/I",
    size: "315x455 (295x445)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
  {
    code: "06-9056",
    format: "20/K",
    size: "370x480 (350x470)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
  {
    code: "06-9156",
    format: "20/K",
    size: "370x480 (350x470)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
  {
    code: "06-9061",
    format: "21/CD",
    size: "200x175 (170x160)",
    color: "envelopesProperties.color.white",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
  {
    code: "06-9161",
    format: "21/CD",
    size: "200x175 (170x160)",
    color: "envelopesProperties.color.brown",
    sealing: "envelopesProperties.sealing.peelSeal",
    boxSize: 50,
  },
]
